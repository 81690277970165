<template>
  <CCard class="vld-parent">
    <loading
      :active.sync="isLoading"
      :is-full-page="false"
      color="#ff993f"
    />
    <CCardBody>
      <CRow>
        <CCol col="12" sm="8">
          <CCardTitle class="mb-0">{{ $t('dial_statistics') }}</CCardTitle>
          <div class="small text-muted">{{ $moment(moment).format('MMM D, YYYY') }}</div>
        </CCol>
        <CCol col="12" sm="4" class="text-right">
          <CLink @click="collapse = !collapse">{{ $t('advanced_search') }} <CIcon :name="collapse ? 'cil-chevron-bottom' : 'cil-chevron-left'" /></CLink>
        </CCol>
      </CRow>
      <CCollapse :show="collapse" :navbar="true">
        <CRow class="mt-2">
          <CCol col="12" sm="8">
            <date-picker
              v-model="moment"
              :shortcuts="false"
              :editable="false"
              :clearable="false"
              :not-after="$moment()"
              width="100%"
              value-type="date"
              format="YYYY-MM-DD"
              :input-class="['form-control', 'bg-white']"
              @change="dateChange"
            />
          </CCol>
          <CCol col="12" sm="4">
            <CSelect
              placeholder="Select interval"
              :options="intervalOptions"
              :value.sync="intervalSec"
              @change="intervalStart(false)"
            >
              <CButton color="dark" size="sm" v-bind="{ variant: 'outline' }" slot="append" @click="intervalStart(true)"><CIcon name="cil-reload" /></CButton>
            </CSelect>
          </CCol>
        </CRow>
      </CCollapse>
      <CChartLine :datasets="chart.datasets" :options="chart.options" :labels="chart.labels" style="height: 300px;margin-top: 40px;" />
    </CCardBody>
    <CCardFooter>
      <CRow class="text-center mb-2">
        <CCol col="12" sm>
          <div class="text-muted">{{ $t('connected') }}</div>
          <h5>{{ statisticsData.connected }}</h5>
        </CCol>
        <CCol col="12" sm>
          <div class="text-muted">{{ $t('not_connected') }}</div>
          <h5>{{ statisticsData.notConnected }}</h5>
        </CCol>
        <CCol col="12" sm>
          <div class="text-muted">{{ $t('connection_rate') }}</div>
          <h5>{{ `${statisticsData.connectionRate} %` }}</h5>
        </CCol>
      </CRow>
    </CCardFooter>
  </CCard>
</template>

<script>
import { CChartLine } from '@coreui/vue-chartjs'
import { hexToRgba } from '@coreui/utils/src'

export default {
  name: 'DialStatistics',
  components: {
    CChartLine
  },
  data () {
    return {
      moment: this.$moment({ hour: 0 }),
      statisticsData: {
        connected: 0,
        notConnected: 0,
        connectionRate: 0
      },
      data: [],
      chart: {
        datasets: [],
        options: {
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'top',
            // onClick: this.onClick,
            // labels: {
            //   boxWidth: 20
            // }
          },
          scales: {
            xAxes: [{
              gridLines: {
                drawOnChartArea: false
              }
            }],
            yAxes: [{
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 11,
                // stepSize: Math.ceil(250 / 10),
                // max: 250
              },
              gridLines: {
                drawOnChartArea: false
              }
            }]
          },
          elements: {
            point: {
              radius: 3,
              borderWidth: 2,
              hitRadius: 1,
              hoverRadius: 5,
              hoverBorderWidth: 3
            },
            line: {
              tension: 0,
              borderWidth: 2
            }
          }
        },
        labels: [],
      },
      intervalOptions: [
        { value: null, label: this.$t('off') },
        { value: 10, label: `10 ${this.$t('seconds')}` },
        { value: 30, label: `30 ${this.$t('seconds')}` },
        { value: 60, label: `1 ${this.$t('minute')}` },
        { value: 300, label: `5 ${this.$t('minutes')}` },
        { value: 900, label: `15 ${this.$t('minutes')}` },
        { value: 1800, label: `30 ${this.$t('minutes')}` },
        { value: 3600, label: `1 ${this.$t('hour')}` }
      ],
      intervalSec: null,
      interval: 0,
      searchData: { date_range: [this.$moment({ hour: 0 }).subtract(2, 'w'), this.$moment({ hour: 0 })], timezone: Intl.DateTimeFormat().resolvedOptions().timeZone },
      collapse: false,
      isLoading: false
    }
  },
  mounted: function () {
    this.intervalStart(true)
  },
  methods: {
    intervalStart (e) {
      clearInterval(this.interval)
      if (this.intervalSec || e) {
        this.getDialStatistics()
      }
      if (this.intervalSec) {
        this.interval = setInterval(() => {
          this.getDialStatistics()
        }, this.intervalSec * 1000)
      }
    },
    getDialStatistics () {
      this.isLoading = true
      const searchData = this.$_.clone(this.searchData)
      searchData.date_range[1] = this.$moment(searchData.date_range[1]).endOf('d')
      this.$store.dispatch('getDialStatistics', this.$_.clone(searchData)).then(res => {
        this.data = res
        this.setDatasets()
        this.isLoading = false
      }).catch(e => {
        this.$swal('Error', e.message ? e.message : e, 'error')
        this.isLoading = false
      })
    },
    setDatasets () {
      const brandSuccess = '#2eb85c'
      const brandDanger = '#e55353'
      const connectedData = [], notConnectedData = []
      const days = Math.ceil(Math.abs(this.searchData.date_range[1].diff(this.searchData.date_range[0], 'd', true)))
      this.chart.labels = []

      const data = this.$_.groupBy(this.data, 'date')
      for (let i = 0; i < days; i++) {
        this.chart.labels.push(this.$moment(this.searchData.date_range[0]).add(i, 'd').format('M/D'))
        connectedData.push(data[this.$moment(this.searchData.date_range[0]).add(i, 'd').format('YYYY-MM-DD')] ? parseInt(data[this.$moment(this.searchData.date_range[0]).add(i, 'd').format('YYYY-MM-DD')][0].connectedCount) : 0)
        notConnectedData.push(data[this.$moment(this.searchData.date_range[0]).add(i, 'd').format('YYYY-MM-DD')] ? parseInt(data[this.$moment(this.searchData.date_range[0]).add(i, 'd').format('YYYY-MM-DD')][0].notConnectedCount) : 0)
      }

      this.statisticsData.connected = this.$_.reduce(connectedData, (memo, num) => { return memo + num; }, 0)
      this.statisticsData.notConnected = this.$_.reduce(notConnectedData, (memo, num) => { return memo + num; }, 0)
      this.statisticsData.connectionRate = this.statisticsData.connected + this.statisticsData.notConnected > 0 ? Math.round(this.statisticsData.connected * 10000 / (this.statisticsData.connected + this.statisticsData.notConnected)) / 100 : 0

      this.chart.datasets = [
        {
          label: this.$t('connected'),
          backgroundColor: hexToRgba(brandSuccess, 10),
          borderColor: brandSuccess,
          pointBackgroundColor: brandSuccess,
          pointHoverBackgroundColor: brandSuccess,
          data: connectedData
        },
        {
          label: this.$t('not_connected'),
          backgroundColor: hexToRgba(brandDanger, 10),
          borderColor: brandDanger,
          pointBackgroundColor: brandDanger,
          pointHoverBackgroundColor: brandDanger,
          data: notConnectedData
        }
      ]
    },
    onClick (e, legendItem) {
      //
    },
    dateChange (e) {
      this.searchData.date_range = [this.$moment(e).subtract(2, 'w'), this.$moment(e)]
      this.intervalStart(true)
    }
  },
  beforeDestroy: function () {
    clearInterval(this.interval)
  }
}
</script>
